import React from "react";
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./components/utility/context/ThemeColors";

// ** Spinner (Splash Screen)
import Spinner from "./components/widgets/spinner/Fallback-spinner";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";
import "@src/components/widgets/ripple-button";
import "./assets/feather/iconfont.css";
import "./components/scss/core.scss";
import "./assets/scss/style.scss";
import "react-loading-skeleton/dist/skeleton.css";
import { DatatableContextProvider } from "./components/widgets/data-table/context/datatable";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
	<Provider store={store}>
		<Suspense fallback={<Spinner />}>
			<DatatableContextProvider>
				<ThemeContext>
					<LazyApp />
					<ToastContainer newestOnTop />
				</ThemeContext>
			</DatatableContextProvider>
		</Suspense>
	</Provider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
