import React, { createContext, useMemo, useState } from "react";
export const DataTableContext = createContext(null);
export const DatatableContextProvider = (props) => {
	const [page, setPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [totalData, setTotalData] = useState(1);
	const [showData, setShowData] = useState(10);
	const [filter, setFilter] = useState("");

	return (
		<DataTableContext.Provider
			value={{
				totalData,
				setTotalData,
				totalPage,
				setTotalPage,
				page,
				setPage,
				showData,
				setShowData,
				filter,
				setFilter,
			}}
		>
			{props.children}
		</DataTableContext.Provider>
	);
};
